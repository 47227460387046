import React, {useState} from "react"
import ReactHtmlParser from "react-html-parser"

// components
import Layout from "../components/layout"

// images
import MessageReceived from "../assets/images/message-received.svg"
import UserProfileRedWarning from "../assets/images/user-profile-red-warning.svg"
import InfoCircle from "../assets/images/info-circle.svg"
import IconClose from "../assets/images/icon-close.svg"

const AccountDeletion = () => {

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)

  if (typeof window !== `undefined`) {

    const form = document.getElementById("formspree");
    
    
    async function handleSubmit(event) {
      event.preventDefault();
      
      var status = document.getElementById("formspree-status");
      var data = new FormData(event.target);

      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
            'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          status.innerHTML = "Thanks for your submission!";
          form.reset()
          setIsSuccessModalVisible(true)
        } else {
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
            } else {
              status.innerHTML = "Oops! There was a problem submitting your form"
            }
          })
        }
      }).catch(error => {
        status.innerHTML = "Oops! There was a problem submitting your form"
      });
      
    }

    form && form.addEventListener("submit", handleSubmit)

  }

  const asterisk = () => {
    return <span style={{color:'#EA646C'}}>*</span>
  }
  
  return (
    <Layout
      className="bg-design vector-dash blue-footer"
      style={{ paddingTop: 0, backgroundColor: "#F9F9F9" }}
    >
      {isSuccessModalVisible && (
        <div className='success-modal-overlay'>
          <div className="success-modal">
            <img src={MessageReceived} alt="" />
            <p>We have received your request</p>
            <p>Your request has been successfully sent. We'll review your account deletion request and get back to you very soon.</p>
            <a href="/" className="btn">Okay!</a>
          </div>
        </div>
      )}

      <div className="account-deletion">
        <div className="container">
          <h2>Account Deletion Request</h2>

          <div className="note">
            <div style={{display:'flex', alignItems: 'center', gap: 8}}>
              <img src={UserProfileRedWarning} alt="" />
              <h3 style={{fontWeight: 600, letterSpacing: 1.15}}>Note on deleting your account:</h3>
            </div>
            <br />
            <p>
              We hate to see you go but please note that account deletion is <strong>permanent</strong>. Afterwards, you will not be able to log in and view your account history and we will hold transactional data for internal audit purposes. Upon successful account deletion, the following personal details, including your full name, email address, mobile number, password, addresses, and payment accounts, will be deleted from our database:<br/>
              <br/>
              If you still have your Servbees App installed, you may also proceed to delete your account instantly in the app.<br />
              <br />
              <div className="learn-more">{ReactHtmlParser('<a href="https://servbees.com/faq-inner/#account?12">Learn more about Account Deletion</a>')}</div>
            </p>
          </div>

          <p>
            Please fill out the form below with the details you used during account registration and we'll review your submission from our end. Account deletion request may be fulfilled within 24 hours for up to 1 week. <br />
            <br />
            <span>{asterisk()} Required field</span>
          </p>

          <form id="formspree" className="form" action="https://formspree.io/f/mayrpvov" method="POST">
            <div className="formspree-container">
              <div>
                <label>Username {asterisk()}</label>
                <input type="text" name="Username" placeholder="Enter your username" required />
              </div>
              <div>
                <label>Email Address {asterisk()}</label>
                <input type="email" name="email" placeholder="Enter your email address" />
              </div>
              <div>
                <label>Mobile Number {asterisk()}</label>
                <input id="mobile-number" type="text" name="Mobile Number" placeholder="+63 Enter your mobile number" required />
              </div>
              <div>
                <label>Display Name (optional)</label>
                <input type="text" name="Display Name" placeholder="Enter your display name" />
              </div>
            </div>

            <div className="caption-submit-container">
              <div className="privacy-caption">
                <img src={InfoCircle} alt="" height={16} width={16} />
                <p className="caption">By submitting, you agree to our {ReactHtmlParser('<a href="/privacy-policy">Privacy Policy</a>')}</p>
              </div>
              <button id="formspree-button" className="btn">Submit</button>
            </div>
            
            <p id="formspree-status"></p>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default AccountDeletion